.t-container {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0;
    font-family: "Roboto", sans-serif;
    border-top: 1px solid var(--shadow-gray);
}

.text{
    font-size: 1.75rem;
    font-weight: 400;
    margin: -1rem 1.45rem;
    background-color: var(--white);
    padding: 0 1.45rem;
    width: fit-content;
    text-transform: uppercase;
}
/* .line{
    margin: -2.6rem 0 0 0;
    border: 1px solid var(--shadow-gray);
    width: 100%;
    z-index: -1;
} */

@media (max-width: 480px) {
    .text {
        font-size: 1.5rem;
        margin: -0.8rem 1.45rem;
    }
}